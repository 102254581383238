import imgBanner from 'images/home/banner.png';
import Coin from 'images/home/coin.svg';
import SavingCoin from 'images/home/saving-coins.svg';
import Promotion from 'images/home/promotion.svg';
import ExchangedGifts from 'images/home/exchangedGifts.svg';
import StorageBox from 'images/home/img-gift_storageBox.png';
import PhoneCards from 'images/home/img-gift_phoneCards.png';
import Pillow from 'images/home/img-gift-pillow.png';
import Scale from 'images/home/img-gift-scale.png';
import BackgroundHomePC from 'images/home/background-homePC.jpg';
import FooterHomePC from 'images/home/background-homePC-footer.png';
import LogoVitaHomePC from 'images/home/logo-homePC.png';
// import QRCodeHomePC from 'images/home/QRcodeHomePC.png';
import QRCodeHomePC from 'images/home/QRcodeHomePC.jpg';
import QRCodeHomePCV2 from 'images/home/QRcodeHomePC.png';

export const BANNER_IMG = [
  {
    src: imgBanner,
    alt: 'banner 1',
  },
  {
    src: imgBanner,
    alt: 'banner 2',
  },
  {
    src: imgBanner,
    alt: 'banner 3',
  },
];

export const IMAGES_HOME = {
  SVGSavingCoin: SavingCoin,
  SVGCoin: Coin,
  SVGPromotion: Promotion,
  SVGExchangedGifts: ExchangedGifts,
};

export const IMAGES_GIFTS = {
  IMGStorageBox: StorageBox,
  IMGPhoneCards: PhoneCards,
  IMGPillow: Pillow,
  IMGScale: Scale,
};

export const TYPE_GIFT = {
  GIFT_CAN_EXCHANGE: {
    TEXT: 'Quà có thể đổi',
    VALUE: 1,
  },
  SPECIAL_GIFT: {
    TEXT: 'Quà đặc biệt Calosure America',
    VALUE: 2,
  },
  ALL_GIFT: {
    TEXT: 'Tất cả các quà',
    VALUE: 3,
  },
  HOT_GIFT: {
    TEXT: 'Quà Hot',
    VALUE: 4,
  },
};

// export const TYPE_OF_GIFT = {
//   E_VOUCHER: 1,
//   PHYSICAL: 2,
// };
export const TYPE_OF_GIFT = {
  E_VOUCHER: 'E-VOUCHER',
  E_VOUCHER_V2: 'E_VOUCHER',
  PHYSICAL: 'GIFT',
};

export const IMAGES_HOME_PC = {
  BACKGROUND: BackgroundHomePC,
  LOGO: LogoVitaHomePC,
  FOOTER: FooterHomePC,
  QR_CODE: QRCodeHomePC,
  QR_CODE_V2: QRCodeHomePCV2,
};

//...............DATA TEST...............................

export const EX_USER_INFO = {
  gender: 'Ông',
  name: 'Công Tằng Tôn Nữ Ngự Bình',
  fullName: '--------Ngự Bình',
  numOfCoins: 20,
  city: 'Hồ Chí Minh',
  phoneNumber: '0987654321',
  physicalGiftExchanged: true,
  lastReceivedAddress: {
    name: 'Nguyễn Văn Bình',
    city: 'HCM',
    district: 'Bình Thạnh',
    wards: '11',
    specificAddress: '78 Nơ Trang Long',
    phoneNumber: '0987654321',
    //
    provinceId: 61,
    provinceName: 'Thành phố Hồ Chí Minh',
    districtId: 649,
    districtName: 'Huyện Bình Chánh',
    wardId: 10527,
    wardName: 'Xã Bình Hưng',
    address: '1A đường abc',
  },
  // physicalGiftExchanged: false,
  provinceId: 61,
  provinceName: 'Thành phố Hồ Chí Minh',
  districtId: 649,
  districtName: 'Huyện Bình Chánh',
  wardId: 10527,
  wardName: 'Xã Bình Hưng',
  address: '1A đường abc',

  firstName: null,
  lastName: null,
  point: 20,
  tierPoint: 68.5,
  avatar: null,
  status: 'LEAD',
  accountStatus: 'ACTIVE',
};

export const EX_LIST_GIFT_CAN_EXCHANGE = [
  {
    name: 'Cân sức khỏe Vitadairy',
    value: 156000,
    coins: 5,
    image: IMAGES_GIFTS.IMGScale,
    remainingAmount: 5,
    description: 'Cân sức khỏe Vitadairy........',
    id: '352',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
  },
  {
    name: 'Thẻ cào điện thoại 10.000đ',
    value: 10000,
    coins: 5,
    image: IMAGES_GIFTS.IMGPhoneCards,
    remainingAmount: 20,
    description:
      'Thẻ nạp mệnh giá 10.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile. Thẻ nạp mệnh giá 10.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile.',
    id: '351',
    typeGift: TYPE_OF_GIFT.E_VOUCHER,
  },
  {
    name: 'Thẻ cào điện thoại 20.000đ',
    value: 20000,
    coins: 10,
    image: IMAGES_GIFTS.IMGPhoneCards,
    remainingAmount: 0,
    description:
      'Thẻ nạp mệnh giá 20.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile.',
    id: '358',
    typeGift: TYPE_OF_GIFT.E_VOUCHER,
  },
  {
    name: 'Thẻ cào điện thoại 50.000đ',
    value: 50000,
    coins: 20,
    image: IMAGES_GIFTS.IMGPhoneCards,
    remainingAmount: 10,
    description:
      'Thẻ nạp mệnh giá 50.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile.',
    id: '354',
    typeGift: TYPE_OF_GIFT.E_VOUCHER,
  },
  {
    name: 'Thẻ cào điện thoại 100.000đ',
    value: 100000,
    coins: 30,
    image: IMAGES_GIFTS.IMGPhoneCards,
    remainingAmount: 2,
    description:
      'Thẻ nạp mệnh giá 100.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile.',
    id: '374',
    typeGift: TYPE_OF_GIFT.E_VOUCHER,
  },
];

export const EX_LIST_SPECIAL_GIFT = [
  {
    name: 'Bộ hộp bảo quản',
    value: 350000,
    coins: 20,
    image: IMAGES_GIFTS.IMGStorageBox,
    remainingAmount: 3,
    description: 'Bộ hộp bảo quản........',
    id: '148',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
  },
  {
    name: 'Gối cao su non Vitadairy',
    value: 180000,
    coins: 15,
    image: IMAGES_GIFTS.IMGPillow,
    remainingAmount: 10,
    description: 'Gối cao su non Vitadairy........',
    id: '143',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
  },
  {
    name: 'Cân sức khỏe Vitadairy',
    value: 156000,
    coins: 5,
    image: IMAGES_GIFTS.IMGScale,
    remainingAmount: 5,
    description: 'Cân sức khỏe Vitadairy........',
    id: '198',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
  },
];

export const EX_LIST_ALL_GIFT = [
  {
    name: 'Bộ hộp bảo quản',
    value: 350000,
    coins: 20,
    image: IMAGES_GIFTS.IMGStorageBox,
    remainingAmount: 3,
    description: 'Bộ hộp bảo quản........',
    id: '123',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
  },
  {
    name: 'Gối cao su non Vitadairy',
    value: 180000,
    coins: 15,
    image: IMAGES_GIFTS.IMGPillow,
    remainingAmount: 10,
    description: 'Gối cao su non Vitadairy........',
    id: '456',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
  },
  {
    name: 'Cân sức khỏe Vitadairy',
    value: 156000,
    coins: 5,
    image: IMAGES_GIFTS.IMGScale,
    remainingAmount: 5,
    description: 'Cân sức khỏe Vitadairy........',
    id: '132',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
  },
  {
    name: 'Thẻ cào điện thoại 10.000đ',
    value: 10000,
    coins: 5,
    image: IMAGES_GIFTS.IMGPhoneCards,
    remainingAmount: 20,
    description:
      'Thẻ nạp mệnh giá 10.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile.',
    id: '133',
    typeGift: TYPE_OF_GIFT.E_VOUCHER,
  },
  {
    name: 'Thẻ cào điện thoại 20.000đ',
    value: 20000,
    coins: 10,
    image: IMAGES_GIFTS.IMGPhoneCards,
    remainingAmount: 0,
    description:
      'Thẻ nạp mệnh giá 20.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile.',
    id: '135',
    typeGift: TYPE_OF_GIFT.E_VOUCHER,
  },
  {
    name: 'Thẻ cào điện thoại 50.000đ',
    value: 50000,
    coins: 20,
    image: IMAGES_GIFTS.IMGPhoneCards,
    remainingAmount: 10,
    description:
      'Thẻ nạp mệnh giá 50.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile.',
    id: '126',
    typeGift: TYPE_OF_GIFT.E_VOUCHER,
  },
  {
    name: 'Thẻ cào điện thoại 100.000đ',
    value: 100000,
    coins: 30,
    image: IMAGES_GIFTS.IMGPhoneCards,
    remainingAmount: 2,
    description:
      'Thẻ nạp mệnh giá 100.000đ sử dung dành cho các nhà mạng Mobiphone, Viettel, Vinaphone, Vietnammobile, Gmobile.',
    id: '146',
    typeGift: TYPE_OF_GIFT.E_VOUCHER,
  },
];

export const EX_SHORTLIST_GIFT_CAN_EXCHANGE = {
  name: 'Quà có thể đổi',
  gifts: EX_LIST_GIFT_CAN_EXCHANGE,
  type: TYPE_GIFT.GIFT_CAN_EXCHANGE.VALUE,
};

export const EX_SHORTLIST_SPECIAL_GIFT = {
  name: 'Quà đặc biệt',
  gifts: EX_LIST_SPECIAL_GIFT,
  type: TYPE_GIFT.SPECIAL_GIFT.VALUE,
};

export const EX_SHORTLIST_ALL_GIFT = {
  name: 'Tất cả các quà',
  gifts: EX_LIST_ALL_GIFT,
  type: TYPE_GIFT.ALL_GIFT.VALUE,
};

export const EX_HISTORY_COIN = [
  {numOfCoins: 3, date: '2023-10-10T09:53:02+07:00'},
  {numOfCoins: 4, date: '2023-10-06T08:40:02+07:00'},
  {numOfCoins: 6, date: '2023-10-02T10:53:02+07:00'},
  {numOfCoins: 5, date: '2023-10-10T09:53:02+07:00'},
  {numOfCoins: 5, date: '2023-10-10T09:53:02+07:00'},
  {numOfCoins: 3, date: '2023-10-10T09:53:02+07:00'},
];

export const EX_EXCHANGED_GIFTS = [
  {
    name: 'Bộ hộp bảo quản',
    value: 350000,
    coins: 20,
    image: IMAGES_GIFTS.IMGStorageBox,
    remainingAmount: 3,
    description: 'Bộ hộp bảo quản........',
    id: '123',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
    exchangedDate: '2023-10-10T09:53:02+07:00',
    receivedAddress: {
      name: 'Nguyễn Văn Bình',
      city: 'HM',
      district: 'Bình Thạnh',
      wards: 'Phường 11',
      specificAddress: '78 Nơ Trang Long',
      phoneNumber: '0987654321',
      provinceName: 'Thành phố Hồ Chí Minh',
      districtName: 'Bình Thạnh',
      wardName: 'Phường 11',
      address: '78 Nơ Trang Long',
    },
  },
  {
    name: 'Gối cao su non Vitadairy',
    value: 180000,
    coins: 15,
    image: IMAGES_GIFTS.IMGPillow,
    remainingAmount: 10,
    description: 'Gối cao su non Vitadairy........',
    id: '456',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
    exchangedDate: '2023-10-15T09:53:02+07:00',
    receivedAddress: {
      name: 'Nguyễn Văn Bình',
      city: 'Hồ Chí Minh',
      district: 'Quận 1',
      wards: 'Phường 2',
      specificAddress: '458 Nguyễn Thị Minh Khai',
      phoneNumber: '0987654321',
      provinceName: 'Thành phố Hồ Chí Minh',
      districtName: 'Quận 1',
      wardName: 'Phường 2',
      address: '458 Nguyễn Thị Minh Khai',
    },
  },
  {
    name: 'Cân sức khỏe Vitadairy',
    value: 156000,
    coins: 5,
    image: IMAGES_GIFTS.IMGScale,
    remainingAmount: 5,
    description: 'Cân sức khỏe Vitadairy........',
    id: '132',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
    exchangedDate: '2023-10-08T09:53:02+07:00',
    receivedAddress: {
      name: 'Nguyễn Văn Bình',
      city: 'Hồ Chí Minh',
      district: 'Quận 1',
      wards: 'Phường 2',
      specificAddress: '458 Nguyễn Thị Minh Khai',
      phoneNumber: '0987654321',
      provinceName: 'Thành phố Hồ Chí Minh',
      districtName: 'Quận 1',
      wardName: 'Phường 2',
      address: '458 Nguyễn Thị Minh Khai',
    },
  },
  {
    name: 'Cân sức khỏe Vitadairy',
    value: 156000,
    coins: 5,
    image: IMAGES_GIFTS.IMGScale,
    remainingAmount: 5,
    description: 'Cân sức khỏe Vitadairy........',
    id: '132',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
    exchangedDate: '2023-10-08T09:53:02+07:00',
    receivedAddress: {
      name: 'Nguyễn Văn Bình',
      city: 'Hồ Chí Minh',
      district: 'Quận 1',
      wards: 'Phường 2',
      specificAddress: '458 Nguyễn Thị Minh Khai',
      phoneNumber: '0987654321',
      provinceName: 'Thành phố Hồ Chí Minh',
      districtName: 'Quận 1',
      wardName: 'Phường 2',
      address: '458 Nguyễn Thị Minh Khai',
    },
  },
  {
    name: 'Bộ hộp bảo quản',
    value: 350000,
    coins: 20,
    image: IMAGES_GIFTS.IMGStorageBox,
    remainingAmount: 3,
    description: 'Bộ hộp bảo quản........',
    id: '123',
    typeGift: TYPE_OF_GIFT.PHYSICAL,
    exchangedDate: '2023-10-10T09:53:02+07:00',
    receivedAddress: {
      name: 'Nguyễn Văn Bình',
      city: 'Hồ Chí Minh',
      district: 'Bình Thạnh',
      wards: 'Phường 11',
      specificAddress: '78 Nơ Trang Long',
      phoneNumber: '0987654321',
      provinceName: 'Thành phố Hồ Chí Minh',
      districtName: 'Bình Thạnh',
      wardName: 'Phường 11',
      address: '78 Nơ Trang Long',
    },
  },
];
