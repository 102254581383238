import {CheckCircleOutline, Search} from '@mui/icons-material';
import {InputAdornment, OutlinedInput} from '@mui/material';
import {getProvinceApi, updateAddressApi} from 'apis/index';
import {LoginContext} from 'App';
import HeaderText from 'components/common/headerText';
import VitaButton from 'components/common/VitaButton';
import {BUTTON_TEXT} from 'constants';
import {filter} from 'lodash';
import {useContext, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import './custom.scss';

const UpdateAddress = ({onSuccess, onBack}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [provinceAll, setProvinceAll] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(true);

  const {userInfo, setIsLoading: setIsLoadingContext} = useContext(LoginContext);

  const customStyle = Object.freeze({
    textfield: {
      input: {
        fontSize: 20,
        fontWeight: 500,
        fontFamily: 'Montserrat',
        lineHeight: 1.3,
        color: '#060606',
        textTransform: 'capitalize',
      },
    },
    inputProps: {
      fontWeight: 600,
      width: '100%',
      '& .MuiInputAdornment-root': {
        color: '#005EB8',
      },
    },
    inputLabelProps: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: '-0.16px',
      color: '#828282',
      '& .MuiInputLabel-asterisk': {color: '#F5293A'},
      '&.Mui-focused': {color: '#005EB8'},
    },
    menuItem: {
      fontSize: 20,
      color: '#060606',
      lineHeight: 1.4,
      fontFamily: 'Montserrat',
      '&.Mui-selected': {backgroundColor: '#005EB8', color: '#fff', fontWeight: 500},
    },
    helperTextProps: {
      fontSize: 16,
      fontFamily: 'Montserrat',
      '&.Mui-error': {color: '#F5293A'},
    },
    outlinedInput: {
      borderRadius: '8px',
      fontFamily: 'Montserrat',
      fontSize: 20,
      lineHeight: 1.4,
      color: '#060606',
      '& .MuiOutlinedInput-input': {paddingTop: '8px', paddingBottom: '8px'},
      '&.Mui-focused': {'& .MuiInputAdornment-root': {color: '#005EB8'}},
    },
  });

  const onChangeSearch = (e) => {
    const value = e.target.value;
    const filteredProvince = provinceAll.filter((province) =>
      province.name.toLowerCase().includes(value.toLowerCase())
    );
    setProvinceOptions(filteredProvince);
  };

  const getProvince = async () => {
    setIsLoading(true);
    try {
      const response = await getProvinceApi();

      if (response?.response?.provinces.length > 0) {
        let provinceData = filter(response?.response?.provinces, {type: 'PROVINCE'});

        setProvinceOptions(provinceData);
        setProvinceAll(provinceData);
      } else {
        setProvinceOptions([]);
        setProvinceAll([]);

        if (response?.meta?.error) {
          toast.error(response?.meta?.msg || 'Hệ thống đang bảo trì, vui lòng thử lại sau.', {
            autoClose: 2000,
            position: 'top-center',
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setProvinceOptions([]);
      setProvinceAll([]);
      console.log('error from getProvince', error);
      toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
        autoClose: 2000,
        position: 'top-center',
      });
    }
  };

  const handleChange = (event) => {
    setSelectedProvince(event.target.value);
  };

  const handleConfirm = () => {
    const params = {
      provinceId: selectedProvince,
    };
    setIsLoadingContext(true);
    updateAddressApi(params)
      .then((res) => {
        if (res?.response?.code === 200) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.log('error from updateAddress', error);
      })
      .finally(() => {
        setIsLoadingContext(false);
      });
  };

  useEffect(() => {
    getProvince();
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      setDisableConfirmBtn(false);
    }
  }, [selectedProvince]);

  return (
    <>
      <HeaderText subClassName="relative">
        <div className={'font-bold'}>Cập nhật địa chỉ</div>
      </HeaderText>
      <div className={'flex flex-col gap-4 px-10 pt-10'}>
        <p className={'font-bold text-center'}>Cập nhật địa chỉ bên dưới để tiếp tục tích xu</p>
        <p className="custom-info-text">CHỌN TỈNH/THÀNH PHỐ</p>
      </div>

      <div className={'px-4'}>
        <div className="custom-select">
          <OutlinedInput
            placeholder="Nhập tên tỉnh/thành phố"
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            onChange={onChangeSearch}
            sx={{...customStyle.outlinedInput}}
          />
          <ul className="select-dropdown-province">
            {provinceOptions?.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={(event) => {
                    handleChange(event, 'provinceId');
                  }}
                  className={item.id === selectedProvince ? 'selected-province' : null}
                  value={item.id}
                >
                  {item.name}
                  <span className="icon">
                    <CheckCircleOutline />
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="fixed bottom-4 px-6 w-full">
        <div className="mb-6">
          <VitaButton disabled={disableConfirmBtn} onClick={handleConfirm}>
            {BUTTON_TEXT.CONFIRM}
          </VitaButton>
        </div>

        <div>
          <VitaButton isOutlined onClick={onBack}>
            {BUTTON_TEXT.BACK}
          </VitaButton>
        </div>
      </div>
    </>
  );
};

export default UpdateAddress;
